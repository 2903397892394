<template>
    <div>
        <h2>1. {{ $t('stap-1-titel') }}</h2>
        <p v-html="$t('stap-1-intro')"></p>
        <form class="mt-5">
            <h4>{{ $t('aantal-clienten-per-mobiliteitsklasse') }}</h4>
            <div class="form-row mb-4">
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.1.a" title="A" mobilityClass="a" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.1.b" title="B" mobilityClass="b" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.1.c" title="C" mobilityClass="c" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.1.d" title="D" mobilityClass="d" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.1.e" title="E" mobilityClass="e" />
                </div>
            </div>
            <h4>{{ $t('aantal-clienten-waarbij-een-elektrisch-hoog-laagbed-wordt-gebruikt') }}</h4>
            <div class="form-row mb-4">
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.2.a" title="A" mobilityClass="a" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.2.b" title="B" mobilityClass="b" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.2.c" title="C" mobilityClass="c" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.2.d" title="D" mobilityClass="d" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.2.e" title="E" mobilityClass="e" />
                </div>
            </div>
            <h4>{{ $t('aantal-clienten-waarbij-glijmateriaal-rolmateriaal-wordt-gebruikt') }}</h4>
            <div class="form-row">
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.3.a" title="A" mobilityClass="a" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.3.b" title="B" mobilityClass="b" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.3.c" title="C" mobilityClass="c" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.3.d" title="D" mobilityClass="d" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="1.3.e" title="E" mobilityClass="e" />
                </div>
            </div>
            <router-link :to="$t('stap-2-pagina')" class="btn btn-primary mt-5">{{ $t('volgende-stap') }} <i class="fas fa-angle-right"></i></router-link>
        </form>
        <span class="sub"><span
            class="color-primary">*</span> {{ $t('andere-effectieve-oplossingen') }}</span>
    </div>
</template>
<script>
import AbcdeField from './components/AbcdeField';

export default {
    name:       'StepOne',
    components: {
        AbcdeField
    }
}
</script>

<template>
    <div class="locale-changer">
        <img class="ml-3 flag" v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang" :src="'img/flags/' + ((lang === 'en') ? 'en.png' : lang + '.svg' )" :alt="lang"
             @click="switchLanguage(lang)">
    </div>
</template>
<script>
export default {
    name: 'locale-changer',
    data() {
        return {
            originalLangs: ['nl', 'en', 'se', 'hu', 'es', 'fr', 'de', 'lt'],
            langs:         []
        }
    },
    methods: {
        switchLanguage(lang) {
            this.$i18n.locale = lang;

            this.updateLanguages();
        },
        updateLanguages() {
            const vm = this;

            this.langs = this.originalLangs.filter((lang) => {
                return lang !== vm.$i18n.locale;
            });
        }
    },
    created() {
        this.updateLanguages();
    },
}
</script>

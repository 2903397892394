import $ from 'jquery';

const jQuery = $;

window.$      = $;
window.jQuery = $;

$(document).ready(function() {
    $('[data-toggle="tooltip"]').tooltip();
});

(function($) {
    "use strict"; // Start of use strict

    // Append .background-image-holder <img>'s as CSS backgrounds
    $('.background-image-holder').each(function() {
        var imgSrc = $(this).children('img').attr('src');
        $(this).css('background', 'url("' + imgSrc + '")');
        $(this).children('img').hide();
        $(this).css('background-position', 'initial');
    });

    // Closes responsive menu when a scroll trigger link is clicked
    $('.js-scroll-trigger').click(function() {
        $('.navbar-collapse').collapse('hide');
    });

    // ACCORDION
    $("#accordion").on("hide.bs.collapse show.bs.collapse", e => {
        $(e.target).prev().find("i:last-child").toggleClass("fa-minus fa-plus");
    });

    $("#accordion").on("shown.bs.collapse", e => {
        $("html, body").animate({
                scrollTop: $(e.target).prev().offset().top - 100
            },
            300);
    });

    // Shrink Navbar
    var navbarCollapse = function() {
        if($('#scroll-indicator').length > 0 && $("#scroll-indicator").offset().top > 80) {
            $("#mainNav").addClass("navbar-shrink");
        } else {
            $("#mainNav").removeClass("navbar-shrink");
        }
    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    $(window).scroll(navbarCollapse);

})(jQuery); // End of use strict

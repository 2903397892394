<template>
    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-12">
                    <SideNavigation />
                </div>
                <div class="col-lg-9 wow fadeIn">
                    <div v-if="showErrorBlock" class="alert alert-danger" role="alert">
                        {{ $t('vul-eerst-alle-velden-in') }}
                    </div>
                    <transition name="fade" mode="out-in">
                        <router-view name="body"></router-view>
                    </transition>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import SideNavigation from "./SideNavigation";

export default {
    name:       'Wrapper',
    data:       function() {
        return {
            showErrorBlock: false
        }
    },
    components: {
        SideNavigation
    },
    watch:      {
        $route(to) {
            if(to.meta.requiresFirstFieldFilledIn) {
                this.redirectIfFirstStepNotFilledIn();
            }
        }
    },
    created() {
        if(this.$route.meta.requiresFirstFieldFilledIn) {
            this.redirectIfFirstStepNotFilledIn();
        }
    },
    methods:    {
        redirectIfFirstStepNotFilledIn() {
            if(/*this.$store.state.organizationName === '' ||
                this.$store.state.departmentName === '' ||*/
                this.$store.state.amountClients === 0 /*||
                this.$store.state.contactName === ''*/) {

                this.showErrorBlock = false;
                this.$router.push('/');
            }
        }
    }
}
</script>

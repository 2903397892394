<template>
    <div>
        <div class="input-group">
            <input type="text" class="form-control" :id="id" placeholder="0" :value="enteredValue" @keyup="validateValue">
        </div>
    </div>
</template>

<script>
export default {
    name:    'NumberField',
    props:   ['id'],
    data() {
        return {
            enteredValue: 0
        }
    },
    methods: {
        validateValue(event) {
            let splitted = this.id.split('.');
            splitted[1]  = '1';
            let firstRow = splitted.join('.');

            let value = parseInt(event.target.value);
            if(isNaN(value)) {
                value = 0;
            }

            if(splitted[0] !== '6') {

                if(firstRow !== this.id) {
                    let question = this.$store.getters.getQuestionById(firstRow);
                    if(typeof question === 'undefined' || value > question.value) {
                        value = question.value;
                    }
                }

                if(firstRow === this.id) {
                    if(this.$store.state.amountClients < value) {
                        value = this.$store.state.amountClients;
                        alert(this.$t('aantal-clienten-error'));
                    }
                }
            }

            this.enteredValue  = value;
            event.target.value = value;

            this.$store.commit('setQuestion', {
                id:    this.id,
                value: value
            });
        }
    },
    mounted() {
        let question = this.$store.getters.getQuestionById(this.id);
        if(typeof question !== 'undefined') {
            this.enteredValue = question.value;
        } else {
            this.enteredValue = 0;
        }
    }
}
</script>

<template>
    <div>
        <section class="pt-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <h2>{{ $t('veel-gestelde-vragen-over-de-tilthermometer') }}</h2>
                        <div id="accordion" class="faq-accordion mb-4">
                            <div class="card" v-bind:key="faq.id" v-for="(faq, index) in faqs">
                                <div class="card-header d-flex align-items-center">
									<span class="faq-title color-dark bold collapsed"
                                          data-toggle="collapse"
                                          :data-target="'#faq' + (index+1)"
                                          aria-expanded="false"
                                          :aria-controls="'faq' + (index+1)">
										{{ faq.question }}
                                        <span class="faq-btn fa-stack fa-sm">
                                            <i class="fas fa-circle fa-stack-2x color-primary"></i>
                                            <i class="fas faq-icon fa-stack-1x fa-inverse"></i>
                                        </span>
									</span>
                                </div>
                                <div :id="'faq' + (index+1)" class="collapse" :aria-labelledby="'faq' + (index+1)" data-parent="#accordion">
                                    <div class="card-body" v-html="faq.answer">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-html="$t('ttm-invullen')"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: 'Faq',
    data: function() {
        return {
            'faqs': require('../../i18n/faq-' + this.$i18n.locale + '.json')
        }
    },
    mounted() {
        const vm = this;
        window.addEventListener('click', (event) => {
            if(event.target.classList.contains('flag')) {
                vm.faqs = require('../../i18n/faq-' + this.$i18n.locale + '.json');
            }
        });
    }
}
</script>

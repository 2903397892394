<template>
    <div>
        <h3>{{ $t('stappen') }}</h3>
        <div class="vertical-menu mb-4">
            <router-link to="/">{{ $t('basisgegevens') }}</router-link>
            <router-link :to="$t('stap-1-pagina')">1. {{ $t('stap-1-titel') }}</router-link>
            <router-link :to="$t('stap-2-pagina')">2. {{ $t('stap-2-titel') }}</router-link>
            <router-link :to="$t('stap-3-pagina')">3. {{ $t('stap-3-titel') }}</router-link>
            <router-link :to="$t('stap-4-pagina')">4. {{ $t('stap-4-titel') }}</router-link>
            <router-link :to="$t('stap-5-pagina')">5. {{ $t('stap-5-titel') }}</router-link>
            <router-link :to="$t('stap-6-pagina')">6. {{ $t('stap-6-titel') }}</router-link>
            <router-link :to="$t('resultaten-pagina')">{{ $t('jouw-resultaten') }}</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SideNavigation'
}
</script>

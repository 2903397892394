<template>
    <div>
        <!-- Header -->
        <section class="pt-0">
            <div class="container bg-gradient">
                <div class="header-content">
                    <div class="row">
                        <div class="col-md-12 header-title">
                            <h4>{{ $t('fysieke-overbelasting-van-zorgmedewerkers-vaststellen') }}</h4>
                            <h1 class="mb-0">{{ $t('tilthermometer-vvt') }}</h1>
                        </div>
                    </div>
                    <transition name="fade" mode="out-in">
                        <div class="row" v-if="showMobilityClassesOn.includes(this.$route.name)">
                            <div class="col-xl-8 col-lg-10 col-md-12 mx-auto">
                                <div class="explain-mob-classes">
                                    <span class="color-body">{{ $t('uitleg-van-de-mobiliteitsklassen') }}</span>
                                    <div class="d-flex justify-content-center align-items-center mt-4">
                                        <div class="flex-fill tooltip-a p-1" data-toggle="tooltip" data-placement="bottom"
                                             :title="$t('uitleg-mobiliteitsklasse-a')">
                                            <img class="mob-class-img" src="img/mob-a.svg">
                                            <h4 class="mb-0 light">A
                                                <svg class="more-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                                    <circle cx="6.737" cy="6.737" r="6.737" transform="translate(0)" />
                                                    <text transform="translate(4.9 10.45)">
                                                        <tspan x="0" y="0">i</tspan>
                                                    </text>
                                                </svg>
                                            </h4>
                                        </div>
                                        <div class="flex-fill p-1" data-toggle="tooltip" data-placement="bottom"
                                             :title="$t('uitleg-mobiliteitsklasse-b')">
                                            <img class="mob-class-img" src="img/mob-b.svg">
                                            <h4 class="mb-0 light">B
                                                <svg class="more-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                                    <circle cx="6.737" cy="6.737" r="6.737" transform="translate(0)" />
                                                    <text transform="translate(4.9 10.45)">
                                                        <tspan x="0" y="0">i</tspan>
                                                    </text>
                                                </svg>
                                            </h4>
                                        </div>
                                        <div class="flex-fill p-1" data-toggle="tooltip" data-placement="bottom"
                                             :title="$t('uitleg-mobiliteitsklasse-c')">
                                            <img class="mob-class-img" src="img/mob-c.svg">
                                            <h4 class="mb-0 light">C
                                                <svg class="more-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                                    <circle cx="6.737" cy="6.737" r="6.737" transform="translate(0)" />
                                                    <text transform="translate(4.9 10.45)">
                                                        <tspan x="0" y="0">i</tspan>
                                                    </text>
                                                </svg>
                                            </h4>
                                        </div>
                                        <div class="flex-fill p-1" data-toggle="tooltip" data-placement="bottom"
                                             :title="$t('uitleg-mobiliteitsklasse-d')">
                                            <img class="mob-class-img" src="img/mob-d.svg">
                                            <h4 class="mb-0 light">D
                                                <svg class="more-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                                    <circle cx="6.737" cy="6.737" r="6.737" transform="translate(0)" />
                                                    <text transform="translate(4.9 10.45)">
                                                        <tspan x="0" y="0">i</tspan>
                                                    </text>
                                                </svg>
                                            </h4>
                                        </div>
                                        <div class="flex-fill p-1" data-toggle="tooltip" data-placement="bottom"
                                             :title="$t('uitleg-mobiliteitsklasse-e')">
                                            <img class="mob-class-img" src="img/mob-e.svg">
                                            <h4 class="mb-0 light">E
                                                <svg class="more-info" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                                    <circle cx="6.737" cy="6.737" r="6.737" transform="translate(0)" />
                                                    <text transform="translate(4.9 10.45)">
                                                        <tspan x="0" y="0">i</tspan>
                                                    </text>
                                                </svg>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import jQuery from 'jquery';

export default {
    name: 'Header',
    data: function() {
        return {
            showMobilityClassesOn: ['home', 'stepOne', 'stepTwo', 'stepThree', 'stepFour', 'stepFive', 'stepSix', 'results']
        }
    },
    mounted() {

        const vm = this;
        this.$router.afterEach(() => {
            if(vm.showMobilityClassesOn.includes(vm.$route.name)) {
                vm.$nextTick(() => {
                    jQuery('[data-toggle="tooltip"]').tooltip('dispose');
                    jQuery('[data-toggle="tooltip"]').tooltip();
                });
            }
        });

        window.addEventListener('click', (event) => {
            if(event.target.classList.contains('flag')) {
                jQuery('[data-toggle="tooltip"]').tooltip('dispose');
                jQuery('[data-toggle="tooltip"]').tooltip();
            }
        });
    }
}
</script>
